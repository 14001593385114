import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"

import Instagram from "../images/instagram.svg"
import Facebook from "../images/facebook.svg"

export const query = graphql`
  {
    allDatoCmsBlog(sort: { order: DESC, fields: fecha }) {
      nodes {
        titulo
        slug
        contenido
        categoriaBlog {
          categoria
        }
        fecha(formatString: "DD/MM/YYYY")
        imagen {
          fluid {
            src
          }
        }
      }
    }
  }
`

const Novedades = ({ data }) => {
  return (
    <Layout>
      <SEO title="Blog de Spiritual Worker" />

      <div className="w-full mx-auto bg-gray-900 pt-5 pt-10 pb-10 lg:pt-20 lg:pb-20">
        <h2 className="titles text-gray-200 text-2xl lg:text-4xl text-center pb-5 lg:pb-20">
          Blog
        </h2>
        <div className="w-full lg:w-10/12 lg:flex mx-auto">
          <div className="hidden md:block w-full lg:w-1/3 rounded-lg p-5">
            <p className="text-yellow-100 p-0 pt-3">
              <div className="social-media">
                <h3 className="text-base">Seguí a Spiritual Worker:</h3>
                <a
                  href="https://www.instagram.com/spiritualworker"
                  target="_blank"
                  rel="noopener"
                  className="block m-3 mt-10 ml-0"
                  style={{
                    backgroundImage: `url(${Instagram})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    paddingLeft: "40px",
                  }}
                >
                  @SpiritualWorker
                </a>
                <a
                  href="https://www.facebook.com/Gisellespiritualworker"
                  target="_blank"
                  rel="noopener"
                  className="block m-3 ml-0"
                  style={{
                    backgroundImage: `url(${Facebook})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    paddingLeft: "40px",
                  }}
                >
                  /Gisellespiritualworker
                </a>
              </div>
            </p>
          </div>

          <div className="w-full lg:w-2/3 mt-5 lg:mt-0">
            {data.allDatoCmsBlog.nodes.map(blog => (
              <div
                className="blogpost texts p-4 pb-10 lg:pb-20"
                key={blog.slug}
              >
                <Link
                  to={`/${blog.slug}/`}
                  className="titles text-gray-200 text-xl lg:text-2xl pb-5 lg:pb-10 hover:text-yellow-200"
                >
                  {blog.titulo}
                </Link>

                <div className="metadata pb-5">
                  <p className="text-sm text-gray-500 p-0 pb-2">
                    Publicado: {blog.fecha}
                  </p>
                  <p className="hidden text-sm text-gray-500 p-0 pb-5">
                    Categoría: {blog.categoriaBlog.categoria}
                  </p>
                </div>

                <Link to={`/${blog.slug}/`}>
                  <img
                    src={blog.imagen.fluid.src}
                    alt={blog.titulo}
                    className="w-8/10"
                  />
                </Link>

                <div
                  className=" text-gray-200 texts mt-6"
                  dangerouslySetInnerHTML={{ __html: blog.contenido }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </Layout>
  )
}

export default Novedades
